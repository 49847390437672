import axios from "axios";
import { destroyToken } from "./Token";
import { axiosBaseUrl } from "./axiosBaseUrl";
import { encruption } from "./encruption";
import { message } from "antd";
import { errorHandler } from "./globalFunction";

const API_URL = process.env.REACT_APP_API_URL;
const TIMEOUT = 10000;

// Define constants for status codes
const STATUS_CODES = {
  UNAUTHORIZED: 401,
  CLIENT_ERROR: 400,
};

// Create an Axios instance
const axiosRequest = axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT,
  headers: {
    common: {
      "X-APP-TOKEN": encruption(),
    },
    get: {
      "X-APP-TOKEN": encruption(),
    },
    post: {
      "X-APP-TOKEN": encruption(),
    },
    put: {
      "X-APP-TOKEN": encruption(),
    },
    delete: {
      "X-APP-TOKEN": encruption(),
    },
    patch: {
      "X-APP-TOKEN": encruption(),
    },
  },
});

// Request interceptor to add Authorization header
axiosRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle errors and token refresh
axiosRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    // Handle non-2xx responses
    if (response && (response.status < 200 || response.status >= 400)) {
      errorHandler(response);
      message.error(`${response.status} ${JSON.stringify(response.data)}`, 30);
    }

    // Handle token refresh logic
    if (response?.status === STATUS_CODES.UNAUTHORIZED) {
      try {
        const refreshResponse = await axiosBaseUrl.get(`auth/v1/refresh_token`);
        if (refreshResponse.status === STATUS_CODES.UNAUTHORIZED) {
          destroyToken();
          window.location.href = "/login"; // Redirect to login if refresh fails
          return Promise.reject(new Error("Unauthorized"));
        }
        localStorage.setItem("token", refreshResponse.data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.accessToken}`;
        return axios(originalRequest); // Retry original request with new token
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export { axiosRequest };