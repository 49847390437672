import { Upload } from "antd";
import React from "react";
import LabelCom from "../label/LabelCom";
import Button from "../button/Button";
import { GrFormUpload } from "react-icons/gr";

function UploadFile({
  name = "file",
  label = "",
  labelOn = "top",
  error = false,
  errorLabel = null,
  labelClassName = "",
  className = "",
  children,
  divClassName = "",
  multiple = false,
  accept = ".pdf,",
  setFileList = () => {},
  fileList = [],
  disabled=false
}) {
  // const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState("");
  // const [fileList, setFileList] = useState([]);
  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  //   console.log("file", file);
  // };
  const handleChange = (e) => {
    const { fileList: newFileList } = e;
    // onChange(e);
    return setFileList(newFileList);
  };

  return (
    <LabelCom
      error={error}
      errorLabel={errorLabel}
      labelClassName={labelClassName}
      label={label}
      labelOn={labelOn}
      divClassName={`p-0 ${divClassName}`}
    >
      <div
        className={`${
          label == "" && labelOn == "top" ? "mt-[29px]" : ""
        } ${className}`}
      >
        <Upload
          name={name}
          action={null}
          // onChange={onChange}
          multiple={multiple}
          fileList={fileList}
          // onPreview={handlePreview}
          onChange={handleChange}
          accept={accept}
        >
          <Button
            htmlType="button"
            className=""
            disabled={fileList.length > 0 || disabled}
            icon={<GrFormUpload id="dontShow" className="w-[20px] h-[20px]" />}
          >
            {children}
          </Button>
        </Upload>
      </div>
    </LabelCom>
  );
}

export default UploadFile;
