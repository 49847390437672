import { Button } from "antd";
import React from "react";
import UploadFile from "./UploadFile";
import Select from "../select/Select";
import { TiDeleteOutline } from "react-icons/ti";
import ButtonCom from "../button/Button";
import Input from "../input/Input";

function UploadFilesWithDatas({
  formState = {},
  setFormState = () => {},
  validationError = {},
  documentTypes = [],
  t = () => {},
  maxFile = 5,
  styleDiv,
  styleDivItem
}) {
  const onRemoveFile = (id) => {
    setFormState({
      ...formState,
      files: formState.files.filter((el) => el.id != id),
    });
  };

  const onChangeFile = (value, key, id) => {
    setFormState({
      ...formState,
      files: formState.files.map((el) => {
        if (el.id == id) {
          return {
            ...el,
            [key]: value,
          };
        }
        return el;
      }),
    });
  };

  const addFile = () => {
    if (formState.files.length < maxFile) {
      setFormState({
        ...formState,
        files: [
          ...formState.files,
          {
            id: Math.floor(Math.random() * 10000),
            file: null,
            doc_type: null,
            doc_number: null,
          },
        ],
      });
    }
  };

  return (
    <div style={styleDiv} className={`border bg-[rgb(183,201,221)] rounded-[10px] p-[10px] mt-[24px] `}>
      {formState.files?.map((el, i) => {
        return (
          <div style={styleDivItem} className="flex flex-wrap">
            <UploadFile
              error={validationError.files?.[i]?.fileList}
              errorLabel={validationError.label}
              setFileList={(e) => onChangeFile(e, "fileList", el.id)}
              fileList={formState.files?.[i]?.fileList}
            >
              {t("document.uploadFile")}
            </UploadFile>
            <Select
              label={t("document.doc_type")}
              error={validationError.files?.[i]?.doc_type}
              errorLabel={validationError.label}
              options={documentTypes.map((el) => ({
                label: el.type?.[t("language")],
                value: el.id,
              }))}
              value={formState.files?.[i]?.doc_type}
              onChange={(e) => onChangeFile(e, "doc_type", el.id)}
              labelClassName="w-[250px]"
              showSearch={true}
            />
            <Input
              label={t("document.doc_number")}
              error={validationError.files?.[i]?.doc_number}
              errorLabel={validationError.label}
              value={formState.files?.[i]?.doc_number}
              onChange={(e) =>
                onChangeFile(e.target.value, "doc_number", el.id)
              }
              className="150px"
            />
            {formState.files?.length > 1 && (
              <div className="mt-[35px]">
                <Button
                  onClick={() => onRemoveFile(el.id)}
                  type="text"
                  className="w-[35px] text-red-500 h-[35px] rounded-[50%] p-0 grid place-content-center"
                >
                  <TiDeleteOutline className="w-[25px] h-[25px]" />
                </Button>
              </div>
            )}
          </div>
        );
      })}
      {formState.files?.length < maxFile && (
        <div className="flex justify-center">
          <ButtonCom
            className="w-[300px] mt-[12px]"
            onClick={() => {
              addFile();
            }}
          >
            {t("buttons.addFile")}
          </ButtonCom>
        </div>
      )}
    </div>
  );
}

export default UploadFilesWithDatas;
