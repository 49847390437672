//////////////////////       Swagger AUTH          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { setAccountingAgreementsData } from "../globalState";

////////      accounting      ////////

////////   Business Trip    ////////
// businessTrip download certificate
export const businessTripDownloadCertificate = createAsyncThunk(
  "businessTripDownloadCertificate",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/businessTrip/download/certificate?_id=${id}&user_id=${user_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// businessTrip approve
export const businessTripApprove = createAsyncThunk(
  "businessTripApprove",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/businessTrip/approve?_id=${id}&user_id=${user_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

////////      Cash      ////////
// get cash document 4
export const getCashDocument4 = createAsyncThunk(
  "getCashDocument4",
  async ({ staff_id, department_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/document4?staff_id=${staff_id}&department_id=${department_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post cash document 4
export const postCashDocument4 = createAsyncThunk(
  "postCashDocument4",
  async ({ body, query, approved }, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/cash/document4${query}`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (approved) {
        // dispatch()
        // dispatch(
        //   setDocsModal({
        //     open: true,
        //     details: {
        //       doc_id: el?.doc_id,
        //     },
        //   })
        // )
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash document 4 approved ones
export const getCashDocument4ApprovedOnes = createAsyncThunk(
  "getCashDocument4ApprovedOnes",
  async ({ staff_id, department_id, year }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/document4/approvedOnes?staff_id=${staff_id}&department_id=${department_id}${year ? `&_year=${year}` : ""
        }`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash book
export const getCashBook = createAsyncThunk("getCashBook", async ({ year }) => {
  try {
    const { data } = await axiosRequest.get(
      `accounting/v1/cash/cach_book?_year=${year}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get cash memorial warrant1
export const getCashMemorialWarrant1 = createAsyncThunk(
  "getCashMemorialWarrant1",
  async ({ year }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/memorial_warrant1?_year=${year}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// cash download document 4
export const cashDownloadDocument4 = createAsyncThunk(
  "cashDownloadDocument4",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/download/document4?_id=${id}&user_id=${user_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// cash download rko
export const cashDownloadRKO = createAsyncThunk(
  "cashDownloadRKO",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/download/rko?_id=${id}&user_id=${user_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash account plan
export const getCashAccountPlan = createAsyncThunk(
  "getCashAccountPlan",
  async ({ id, user_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/account_plan`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post cash account plan
export const postCashAccountPlan = createAsyncThunk(
  "postCashAccountPlan",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/cash/account_plan`,
        body
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash memorial warrant8
export const getCashMemorialWarrant8 = createAsyncThunk(
  "getCashMemorialWarrant8",
  async ({ year }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/memorial_warrant8?_year=${year}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// post cash arrival_order
export const postCashArrivalOrder = createAsyncThunk(
  "postCashArrivalOrder",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `accounting/v1/cash/arrival_order`,
        body,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get cash arrival_order
export const getCashArrivalOrder = createAsyncThunk(
  "getCashArrivalOrder",
  async ({ year }) => {
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/cash/arrival_order?_year=${year}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
// accounting/v1/counterparty

export const postUpdateAgreements = createAsyncThunk(
  "postUpdateAgreements",
  async ({ head, body }, { dispatch }) => {
    try {
      const params = new URLSearchParams();
        params.append('user_id', head.user_id);
        params.append('name', head.name);
        params.append('itn', head.itn);
        params.append('bank_details', head.bank_details);
        params.append('doc_types', head.doc_types);
        params.append('metadatas', head.metadatas);
        const { data } = await axiosRequest.post(
          `accounting/v1/counterparty?${params.toString()}`,
          body,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        dispatch(getUpdateAgreements());
        return data;
      

    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getUpdateAgreements = createAsyncThunk(
  "getUpdateAgreements",
  async (itn = null, { rejectWithValue }) => {   
    try {
      const { data } = await axiosRequest.get(
        `accounting/v1/counterparty${itn ? `/itn?itn=${itn}` : ''}`
      );

      console.log(data);
      return data;

    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response?.data || "Ошибка загрузки данных");
    }
  }
);
// if (!body) {
//   params.append('itn', head.itn);
//   const { data } = await axiosRequest.post(
//     `accounting/v1/counterparty?${params.toString()}`,
//     null,
//     {
//       headers: { "Content-Type": "multipart/form-data" },
//     }
//   );
//   dispatch(getUpdateAgreements());
//   return data;
// } 
