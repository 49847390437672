import { Input } from "antd";
import React from "react";
import LabelCom from "../label/LabelCom";
import ReactInputMask from "react-input-mask";

function InputCom({
  id = null,
  label = "",
  labelOn = "top",
  error = false,
  errorLabel = null,
  className,
  labelClassName,
  placeholder = "",
  name = null,
  type = "text",
  value = null,
  onChange = null, //(e) => {console.log(e.target.value)},
  between = false,
  disabled = false,
  editable = true,
  defaultValue = "",
  required = false,
  mask = null,
  maskChar=null,
  divClassName='',
  autocomplete="off",
  visibilityToggle=false
}) {
  if (mask) {
    return (
      <LabelCom
        labelClassName={labelClassName}
        label={label}
        error={error}
        errorLabel={errorLabel}
        labelOn={labelOn}
        between={between}
        divClassName={divClassName}
      >
        <ReactInputMask
          mask={mask} //
          maskChar={maskChar}//
          id={id}
          className={`w-full min-w-[50px] inputCom ${className}`}
          placeholder={placeholder}
          name={name}
          type={type}
          disabled={disabled}
          defaultValue={defaultValue}
          required={required}
          value={value}
          onChange={onChange}
          autocomplete={autocomplete} visibilityToggle={visibilityToggle}
        >
          {(inputProps) => <Input {...inputProps} />}
        </ReactInputMask>
      </LabelCom>
    );
  }

  if (!editable) {
    return (
      <LabelCom
        labelClassName={labelClassName}
        label={label}
        labelOn={labelOn}
        between={between}
        divClassName={divClassName}
      >
        <Input
          className={`w-full min-w-[50px] inputCom ${className}`}
          placeholder={placeholder}
          name={name}
          type={type}
          disabled={disabled}
          value={value} //
          required={required}
          autocomplete={autocomplete} visibilityToggle={visibilityToggle}
        />
      </LabelCom>
    );
  }

  if (onChange) {
    return (
      <LabelCom
        labelClassName={labelClassName}
        label={label}
        error={error}
        errorLabel={errorLabel}
        labelOn={labelOn}
        between={between}
        divClassName={divClassName}
      >
        <Input
          id={id}
          className={`w-full min-w-[50px] inputCom ${className}`}
          placeholder={placeholder}
          name={name}
          type={type}
          disabled={disabled}
          value={value} //
          onChange={onChange} //
          defaultValue={defaultValue}
          required={required}
          autocomplete={autocomplete} visibilityToggle={visibilityToggle}
        />
      </LabelCom>
    );
  }

  return (
    <LabelCom
      labelClassName={labelClassName}
      label={label}
      error={error}
      errorLabel={errorLabel}
      labelOn={labelOn}
      between={between}
      divClassName={divClassName}
    >
      <Input
        id={id}
        classxName={`w-full min-w-[50px] inputCom ${className}`}
        placeholder={placeholder}
        name={name} //
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        required={required}
        autoСomplete={autocomplete} visibilityToggle={visibilityToggle}
      />
    </LabelCom>
  );
}

export default InputCom;
