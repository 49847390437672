import { Select as SelectAnt } from "antd";
import React from "react";
import LabelCom from "../label/LabelCom";

function Select({
  label = "",
  labelOn = "top",
  error = false,
  errorLabel = null,
  className,
  labelClassName,
  placeholder = "",
  name = null,
  value = null,
  onChange = null,
  defaultValue = null,
  between = false,
  options = [],
  required = false,
  ref = null,
  allowClear = false,
  mode = "",
  disabled = false,
  optionFilterProp = "label",
  showSearch = false,
  divClassName = "",
}) {
  if (onChange) {
    return (
      <LabelCom
        label={label}
        labelOn={labelOn}
        error={error}
        errorLabel={errorLabel}
        labelClassName={labelClassName}
        divClassName={divClassName}
      >
        <SelectAnt
          className={`select_com w-full min-w-[50px] max-w-[250px] ${className}`}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
          options={options}
          required={required}
          ref={ref}
          allowClear={allowClear}
          mode={mode}
          disabled={disabled}
          optionFilterProp={optionFilterProp}
          // filterSort={(optionA, optionB) =>
          //   (optionA?.label ?? "")
          //     .toLowerCase()
          //     .localeCompare((optionB?.label ?? "").toLowerCase())
          // }
          showSearch={showSearch}
        />
      </LabelCom>
    );
  } else {
    return (
      <LabelCom
        label={label}
        labelOn={labelOn}
        error={error}
        errorLabel={errorLabel}
        labelClassName={labelClassName}
        divClassName={divClassName}
      >
        <SelectAnt
          className={`select_com w-full min-w-[50px] ${className}`}
          placeholder={placeholder}
          name={name}
          defaultValue={defaultValue}
          options={options}
          required={required}
          ref={ref}
          allowClear={allowClear}
          mode={mode}
          disabled={disabled}
          optionFilterProp={optionFilterProp}
          // filterSort={(optionA, optionB) =>
          //   (optionA?.label ?? "")
          //     .toLowerCase()
          //     .localeCompare((optionB?.label ?? "").toLowerCase())
          // }
          showSearch={showSearch}
        />
      </LabelCom>
    );
  }
}

export default Select;
