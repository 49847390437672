import {
  setDepartmentId,
  setSelectedStaffs,
  setStaff,
  setStaffs,
  setStaffValidationState,
} from "../redux/reducers/globalState";
import { message } from "antd";
import { queriesMessage } from "../constanst/const";

export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function diramToSomoni(diram) {
  if (!(diram / 100)) return "- - -";
  else {
    let somoni = diram / 100;
    somoni = "" + somoni;
    somoni = `${somoni?.split(".")?.[0]}.${somoni?.split(".")?.[1]?.[0]}${
      somoni?.split(".")?.[1]?.[1]
    }`;
    return somoni;
  }
}

export function errorHandler(response) {
  if (response.status === 200) {
  } else if (response.status === 401 || response.status === 403) {
    message.error(`${response.status} ${JSON.stringify(response.data)}`);
  } else if (response.status === 409 || response.status === 422) {
    if (
      queriesMessage &&
      response.data?.details &&
      queriesMessage?.[response.data?.details]
    ) {
      // queriesMessage?.[response.data?.details];
    } else {
      message.error(`${response.status} ${JSON.stringify(response.data)}`);
    }
  } else {
    message.error(`${response.status} ${JSON.stringify(response.data)}`);
  }
}

// currentYear
let currentYear = new Date().getFullYear();

export const setCurrentYear = (year) => {
  currentYear = year;
};

export const getCurrentYear = () => currentYear;

export const resetLeftMenu = (dispatch, doNotClear = []) => {
  if (!doNotClear.includes(1)) {
    dispatch(setStaff({}));
    dispatch(setSelectedStaffs([]));
  }
  if (!doNotClear.includes(2)) {
    dispatch(setStaffs([]));
  }
  if (!doNotClear.includes(3)) {
    dispatch(setDepartmentId(null));
  }
  if (!doNotClear.includes(4)) {
    dispatch(setStaffValidationState({ erro: false, label: null }));
  }
};

export function handleBinaryData(
  arrayBuffer,
  filename = "file.xlsx",
  type = "application/octet-stream"
) {
  // Process the ArrayBuffer here
  // For example, converting it to a Blob for further use
  const blob = new Blob([arrayBuffer], { type });

  // Optionally create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // You can now use the Blob URL to download or display the file
  // Example: create a download link
  const a = document.createElement("a");
  a.href = url;
  a.download = filename; // Specify a file name
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Clean up
  URL.revokeObjectURL(url);
}

export const showDate = (date) => {
  if (date) {
    let yyyy = new Date(date).getFullYear() + "";
    let mm = new Date(date).getMonth() + 1 + "";
    let dd = new Date(date).getDate() + "";
    if (mm.length === 1) mm = "0" + mm;
    if (dd.length === 1) dd = "0" + dd;
    return `${yyyy}-${mm}-${dd}`;
  }
  return "- - -";
};

export const putZero = (num) => {
  if (num < 10) return `0${num}`;
  else return `${num}`;
};

export const putDepartmentChildrens = (department) => {
  let newDepartmentParents = [];
  let newDepartmentChildrens = [];

  department.map((el) => {
    if (!el.parent_id) {
      newDepartmentParents.push(el);
    } else {
      newDepartmentChildrens.push(el);
    }
  });
  newDepartmentParents = newDepartmentParents.map((el) => {
    if (newDepartmentChildrens.find((e) => e.parent_id === el.id)) {
      return {
        ...el,
        children: newDepartmentChildrens.filter((e) => e.parent_id === el.id),
        // disabled: true,
        // title:<button onClick={()=>alert('hello')}>{el.label}</button>
      };
    }
    return el;
  });
  return newDepartmentParents;
};

export const toBase64 = (file) =>
  new Promise((resolve, rejective) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => rejective(error);
  });
