import React from "react";
import { Button } from "antd";
import LabelCom from "../label/LabelCom";

function ButtonCom({
  htmlType = "button",
  className = "",
  type = "default",
  children,
  disabled = false,
  onClick = () => {},
  label = "",
  labelOn = "top",
  labelClassName,
  style = {},
  icon = null,
  iconOn = "left",
}) {
  return (
    <LabelCom label={label} labelClassName={`m-0 ${labelClassName}`} labelOn={labelOn}>
      <Button
        htmlType={htmlType}
        disabled={disabled}
        className={`buttonCom ${ 
          icon
            ? `flex items-center justify-center gap-[10px] ${
                iconOn == "right" ? "flex-row-reverse" : ""
              }`
            : ""
        } button_com ${className}`}
        type={type}
        onClick={onClick}
        style={style}
      >
        {icon}
        {children}
      </Button>
    </LabelCom>
  );
}

export default ButtonCom;
